import React from "react";

export default function CaretUp({ textColor }) {
  return (
    <div className="caret-up-icon">
      <svg
        width="14"
        height="9"
        viewBox="0 0 14 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 7.37845L7 0.711783L13 7.37845"
          stroke={textColor}
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
