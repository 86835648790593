import React from "react";

export default function XIcon({ color, size }) {
  return (
    <div className="x-icon">
      <svg
        width={size}
        height={size}
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Frame">
          <path
            id="Vector"
            d="M7 25L25 7"
            stroke={color}
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M7 7L25 25"
            stroke={color}
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
}
