
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import {storage} from '../Firebase';




export  const brochureDownload=(path,brochure_link)=>{
    getDownloadURL(ref(storage, path))
    .then((url) => {
      
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = (event) => {
        const blob = xhr.response;
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = brochure_link;
        link.click();
        URL.revokeObjectURL(link.href);
      };
      xhr.open('GET', url);
      xhr.send();
    })
    .catch((error) => {
      console.log(error)
    });
};

export const getAllPhotos=(path)=>{
  const listRef=ref(storage, `galerie-corporate/${path}`);
  listAll(listRef).then((res)=>{
    res.items.forEach((itemRef)=>{
      getDownloadURL(itemRef)
      .then((url) => {
        const path=itemRef._location.path_.split('/')[2].split('.')[0]
        const img = document.getElementById(path);
        try{
          img.setAttribute('src', url);
        }catch(error){}
     
      })
      .catch((error) => {
        // Handle any errors
        console.log(error)
      });
    })
  })
};

export const getSingleImage=(path,id)=>{
  getDownloadURL(ref(storage, `galerie-corporate/${path}`))
  .then((url) => {
    const img = document.getElementById(id);
    try{
      img.setAttribute('src', url);
    }catch(error){}
 
  })
  .catch((error) => {
    // Handle any errors
    console.log(error)
  });
};

