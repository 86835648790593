import React, { useState } from "react";
import { Link } from "react-router-dom";
import CaretUp from "../icons/CaretUp";
import CaretDown from "../icons/CaretDown";

export default function HamburgerMenuExpanded({
  backgroundColor,
  textColor,
  handleExpand,
}) {
  const [hamburgerDrop1, setHamburgerDrop1] = useState(false);
  const [hamburgerDrop2, setHamburgerDrop2] = useState(false);
  const [hamburgerDrop3, setHamburgerDrop3] = useState(false);

  const handleHamburgerDrop = (num) => {
    switch (num) {
      case "1":
        setHamburgerDrop1(!hamburgerDrop1);
        break;
      case "2":
        setHamburgerDrop2(!hamburgerDrop2);
        break;
      case "3":
        setHamburgerDrop3(!hamburgerDrop3);
        break;
    }
  };

  return (
    <div className="expanded-container" style={{ background: backgroundColor }}>
      <div className="navbar-buttons-expanded">
        <div className="hamburger-dropdown-container">
          <div className="hamburger-dropdown-div">
            <Link
              to="/about"
              onClick={handleExpand}
              className="link-expanded"
              data-testid="mobile-navbar-link-about"
              style={{ color: textColor }}
            >
              About Us
            </Link>
            <div
              className="hamburger-caret-div"
              onClick={() => handleHamburgerDrop("1")}
            >
              {hamburgerDrop1 ? (
                <>
                  {" "}
                  <CaretUp className="hamburger-caret" textColor={textColor} />
                </>
              ) : (
                <>
                  <CaretDown
                    textColor={textColor}
                    className="hamburger-caret"
                  />
                </>
              )}
            </div>
          </div>
          {hamburgerDrop1 && (
            <>
              <div className="hamburger-options">
                <Link
                  to="/healthsustainability"
                  onClick={handleExpand}
                  className="option-expanded"
                  data-testid="mobile-navbar-link-about"
                  style={{ color: textColor }}
                >
                  Health Sustainability
                </Link>
                <Link
                  to="/team"
                  onClick={handleExpand}
                  className="option-expanded"
                  data-testid="mobile-navbar-link-about"
                  style={{ color: textColor }}
                >
                  Team
                </Link>
                <Link
                  to="/careers"
                  onClick={handleExpand}
                  className="option-expanded"
                  data-testid="mobile-navbar-link-about"
                  style={{ color: textColor }}
                >
                  Careers
                </Link>
                <Link
                  to="/press"
                  onClick={handleExpand}
                  className="option-expanded"
                  data-testid="mobile-navbar-link-about"
                  style={{ color: textColor }}
                >
                  Press
                </Link>
              </div>
            </>
          )}
        </div>

        <div
          className="nav-divider"
          style={{ borderTop: `1px solid ${textColor}` }}
        ></div>

        <div className="hamburger-dropdown-container">
          <div className="hamburger-dropdown-div">
            <Link
              to="/collections"
              onClick={handleExpand}
              className="link-expanded"
              data-testid="mobile-navbar-link-collection"
              style={{ color: textColor }}
            >
              The Collection
            </Link>
            <div
              className="hamburger-caret-div"
              onClick={() => handleHamburgerDrop("2")}
            >
              {hamburgerDrop2 ? (
                <>
                  {" "}
                  <CaretUp
                    textColor={textColor}
                    className="hamburger-caret"
                    size={20}
                  />
                </>
              ) : (
                <>
                  <CaretDown
                    textColor={textColor}
                    className="hamburger-caret"
                    size={20}
                  />
                </>
              )}
            </div>
          </div>
          {hamburgerDrop2 && (
            <>
              <div className="hamburger-options">
                <Link
                  to="/villagepark"
                  onClick={handleExpand}
                  className="option-expanded"
                  data-testid="mobile-navbar-link-about"
                  style={{ color: textColor }}
                >
                  Village Park
                </Link>
                <Link
                  to="/corso"
                  onClick={handleExpand}
                  className="option-expanded"
                  data-testid="mobile-navbar-link-about"
                  style={{ color: textColor }}
                >
                  Corso
                </Link>
              </div>
            </>
          )}
        </div>

        <div
          className="nav-divider"
          style={{ borderTop: `1px solid ${textColor}` }}
        ></div>

        <div className="hamburger-dropdown-container">
          <div className="hamburger-dropdown-div">
            <Link
              to="/companies"
              onClick={handleExpand}
              className="link-expanded"
              data-testid="mobile-navbar-link-companies"
              style={{ color: textColor }}
            >
              Our Companies
            </Link>
            <div
              className="hamburger-caret-div"
              onClick={() => handleHamburgerDrop("3")}
            >
              {hamburgerDrop3 ? (
                <>
                  {" "}
                  <CaretUp
                    textColor={textColor}
                    className="hamburger-caret"
                    size={20}
                  />
                </>
              ) : (
                <>
                  <CaretDown
                    textColor={textColor}
                    className="hamburger-caret"
                    size={20}
                  />
                </>
              )}
            </div>
          </div>
          {hamburgerDrop3 && (
            <>
              <div className="hamburger-options">
                <Link
                  to="/capital"
                  onClick={handleExpand}
                  className="option-expanded"
                  data-testid="mobile-navbar-link-about"
                  style={{ color: textColor }}
                >
                  Galerie Capital
                </Link>
                <Link
                  to="/development"
                  onClick={handleExpand}
                  className="option-expanded"
                  data-testid="mobile-navbar-link-about"
                  style={{ color: textColor }}
                >
                  Galerie Development
                </Link>
                <Link
                  to="/management"
                  onClick={handleExpand}
                  className="option-expanded"
                  data-testid="mobile-navbar-link-about"
                  style={{ color: textColor }}
                >
                  Galerie Management
                </Link>
              </div>
            </>
          )}
        </div>

        <div
          className="nav-divider"
          style={{ borderTop: `1px solid ${textColor}` }}
        ></div>

        <Link
          to="/contact"
          onClick={handleExpand}
          className="contact-us-btn"
          data-testid="mobile-navbar-link-contact"
          style={{ color: textColor, textDecoration: "none" }}
        >
          Contact Us
        </Link>
      </div>
    </div>
  );
}
