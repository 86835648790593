import React, { Fragment, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";
import CaretDown from "../icons/CaretDown";
export default function CustomDropdown({
  textColor,
  background_color,
  dropdownTitle,
  dropdownLink,
  itemsDict,
  setNavHoverDim,
}) {
  const [showDropdown, setShowDropdown] = useState(false);

  const max_width = useMediaPredicate("(min-width: 1200px)");
  const handleShow = () => {
    setShowDropdown(true);
    setNavHoverDim(true);
  };

  const handleClose = () => {
    setShowDropdown(false);
    setNavHoverDim(false);
  };

  const handleSlowClose = () => {
    setShowDropdown(false);
    setNavHoverDim(false);
  };
  return (
    <div
      className="nav-dropdown-div "
      onMouseLeave={handleClose}
      onMouseOver={handleShow}
    >
      <div className="dropdown-title-div">
        <Link
          to={`/${dropdownLink}`}
          className={`div-copy navbar-link dropdown-title ${textColor}`}
          data-testid={`${dropdownLink}-navbar-link`}
          style={{ color: textColor }}
          onClick={handleClose}
        >
          {dropdownTitle}
        </Link>
        <CaretDown
          textColor={textColor}
          className="dropdown-caret"
          onClick={handleClose}
          style={
            max_width
              ? { marginLeft: "15px", marginBottom: "3px" }
              : { marginLeft: "5px", marginBottom: "3px" }
          }
        />

        <div
          className={`hover-div ${dropdownTitle
            .replace(" ", "")
            .toLowerCase()}`}
          onMouseLeave={handleClose}
          onMouseOver={handleShow}
        ></div>
      </div>

      <div
        style={{ backgroundColor: background_color }}
        className={
          showDropdown
            ? `custom-dropdown-menu ${textColor}`
            : `custom-dropdown-menu-closed`
        }
        onMouseLeave={handleClose}
        onMouseOver={handleShow}
      >
        {itemsDict.map((item, i) => (
          <Fragment key={i}>
            <div className={`nav-dropdown-item-div ${textColor}`}>
              <Link
                style={{ textDecoration: "none", color: textColor }}
                to={`/${item.link}`}
                data-testid={`${item.title}-navbar-link`}
                onClick={handleSlowClose}
              >
                <p
                  className={`nav-dropdown-item ${textColor}`}
                  data-testid={`${item.title}-navbar-link`}
                >
                  {item.title}
                </p>
              </Link>
            </div>
          </Fragment>
        ))}
      </div>

      {/* } */}
    </div>
  );
}
