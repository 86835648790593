import React from "react";
import { useMediaPredicate } from "react-media-hook";
import DarkRedButton from "./DarkRedButton";

export default function CardGrey({
  imgId,
  cardText = "",
  cardTitle = "",
  buttonLink = false,
  buttonLinkExternal = false,
  buttonText = "learn more",
  flexStyle = "row",
  class_name = "",
  width = "992px",
  data_test_id = "",
  alt_tag = "",
  cardText2 = false,
  buttonShown = true,
}) {
  const max_width = useMediaPredicate(`(max-width: ${width})`);

  return (
    <div
      className={`card-grey ${class_name}`}
      style={
        max_width ? { flexDirection: "column" } : { flexDirection: flexStyle }
      }
    >
      <img loading="lazy" alt={alt_tag} className="card-grey-img" id={imgId} />
      <div className="card-grey-body">
        <h2 className="card-grey-body-title">{cardTitle}</h2>
        <p className="card-grey-body-text">{cardText}</p>
        {cardText2 ? (
          <>
            <p className="card-grey-body-text">{cardText2}</p>
          </>
        ) : (
          <></>
        )}
        {buttonShown ? (
          <>
            {buttonLink ? (
              <>
                <DarkRedButton
                  class_name={"card-grey-button"}
                  btnText={buttonText}
                  btnLink={buttonLink}
                  btnWidth={width}
                  data_test_id={data_test_id}
                />
              </>
            ) : (
              <>
                <DarkRedButton
                  class_name={"card-grey-button"}
                  btnText={buttonText}
                  btnLinkExternal={buttonLinkExternal}
                  btnWidth={width}
                  data_test_id={data_test_id}
                />
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
