import React, { useEffect, useState, lazy, Suspense } from "react";
import "./App.css";
import "./styles/styles.scss";
import { storage } from "./Firebase";
import "bootstrap/dist/css/bootstrap.min.css";
import { db } from "./Firebase";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./navbar/Navbar";
import Footer from "./footer/Footer";
import { getDownloadURL, ref } from "firebase/storage";
import Home from "./home/Home";
import HealthSustainability from "./health_sustainability/HealthSustainability";
import { useMediaPredicate } from "react-media-hook";
import { ParallaxProvider } from "react-scroll-parallax";
const AboutUs = lazy(() => import("./about_us/AboutUs"));
const CorsoPage = lazy(() => import("./corso_page/CorsoPage"));
const Careers = lazy(() => import("./careers/Careers"));
const Press = lazy(() => import("./press/Press"));
const ContactUs = lazy(() => import("./contact_us/ContactUs"));
const Collections = lazy(() => import("./collection/Collections"));
const Companies = lazy(() => import("./companies/Companies"));
const GalerieCapital = lazy(() => import("./galerie_capital/GalerieCapital"));
const Team = lazy(() => import("./team/Team"));
const VillagePark = lazy(() => import("./village_park/VillagePark"));
const Management = lazy(() => import("./management/Management"));
const Development = lazy(() => import("./development/Development"));
const NotFound = lazy(() => import("./error_page/NotFound"));

function App() {
  const max_width = useMediaPredicate("(max-width: 1000px)");
  const max_width2 = useMediaPredicate("(max-width: 1230px)");
  const [navBackground, setNavBackground] = useState("white");
  const [navText, setNavText] = useState("black");
  const [navHoverDim, setNavHoverDim] = useState(false);

  const slide_right = {
    initial: max_width2 ? { x: 0 } : { x: -25, opacity: 0 },
    whileInView: { x: 0, opacity: 1 },
    transition: max_width2 ? { duration: 0 } : { type: "tween", duration: 0.7 },
  };
  const slide_left = {
    initial: max_width2 ? { x: 0 } : { x: 25, opacity: 0 },
    whileInView: { x: 0, opacity: 1 },
    transition: max_width2 ? { duration: 0 } : { type: "tween", duration: 0.7 },
  };
  const handleCurrentPage = () => {
    if (window.location.pathname !== "/contact") {
      setNavBackground("white");
      setNavText("black");
    }
  };

  const getSingleImage = (path, id) => {
    getDownloadURL(ref(storage, `galerie-corporate/${path}`))
      .then((url) => {
        const img = document.getElementById(id);
        try {
          img.setAttribute("src", url);
        } catch (error) {}
      })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });
  };

  useEffect(() => {
    handleCurrentPage();
  }, []);

  return (
    <ParallaxProvider>
      <BrowserRouter>
        <div className="App">
          <Navbar
            backgroundColor={navBackground}
            textColor={navText}
            setNavHoverDim={setNavHoverDim}
            navHoverDim={navHoverDim}
          />
          <div
            style={
              max_width ? { paddingBottom: "0px" } : { paddingBottom: "100px" }
            }
          ></div>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  slide_right={slide_right}
                  handleCurrentPage={handleCurrentPage}
                  storage={storage}
                  db={db}
                  getSingleImage={getSingleImage}
                />
              }
            />
            <Route
              path="/corso"
              element={
                <Suspense fallback={<>...</>}>
                  <CorsoPage
                    slide_left={slide_left}
                    slide_right={slide_right}
                    storage={storage}
                    handleCurrentPage={handleCurrentPage}
                    db={db}
                  />
                </Suspense>
              }
            />
            <Route
              path="/careers"
              element={
                <Suspense fallback={<>...</>}>
                  <Careers
                    slide_left={slide_left}
                    slide_right={slide_right}
                    handleCurrentPage={handleCurrentPage}
                    storage={storage}
                    db={db}
                    getSingleImage={getSingleImage}
                  />
                </Suspense>
              }
            />
            <Route
              path="/press"
              element={
                <Suspense fallback={<>...</>}>
                  <Press
                    slide_right={slide_right}
                    handleCurrentPage={handleCurrentPage}
                    db={db}
                    storage={storage}
                  />
                </Suspense>
              }
            />
            <Route
              path="/contact"
              element={
                <Suspense fallback={<>...</>}>
                  <ContactUs
                    slide_right={slide_right}
                    db={db}
                    handleCurrentPage={handleCurrentPage}
                    storage={storage}
                    getSingleImage={getSingleImage}
                    setBackgroundColor={setNavBackground}
                    setTextColor={setNavText}
                  />
                </Suspense>
              }
            />
            <Route
              path="/collections"
              element={
                <Suspense fallback={<>...</>}>
                  <Collections
                    slide_left={slide_left}
                    slide_right={slide_right}
                    Collections
                    handleCurrentPage={handleCurrentPage}
                    storage={storage}
                    db={db}
                    getSingleImage={getSingleImage}
                  />
                </Suspense>
              }
            />
            <Route
              path="/about"
              element={
                <Suspense fallback={<>...</>}>
                  <AboutUs
                    slide_right={slide_right}
                    handleCurrentPage={handleCurrentPage}
                    storage={storage}
                    db={db}
                    getSingleImage={getSingleImage}
                  />
                </Suspense>
              }
            />
            <Route
              path="/companies"
              element={
                <Suspense fallback={<>...</>}>
                  <Companies
                    slide_left={slide_left}
                    slide_right={slide_right}
                    handleCurrentPage={handleCurrentPage}
                    storage={storage}
                    db={db}
                    getSingleImage={getSingleImage}
                  />
                </Suspense>
              }
            />
            <Route
              path="/capital"
              element={
                <Suspense fallback={<>...</>}>
                  <GalerieCapital
                    slide_left={slide_left}
                    slide_right={slide_right}
                    handleCurrentPage={handleCurrentPage}
                    storage={storage}
                    db={db}
                  />
                </Suspense>
              }
            />
            <Route
              path="/team"
              element={
                <Suspense fallback={<>...</>}>
                  <Team
                    slide_left={slide_left}
                    slide_right={slide_right}
                    handleCurrentPage={handleCurrentPage}
                    storage={storage}
                    db={db}
                    getSingleImage={getSingleImage}
                  />
                </Suspense>
              }
            />
            <Route
              path="/villagepark"
              element={
                <Suspense fallback={<>...</>}>
                  <VillagePark
                    slide_left={slide_left}
                    slide_right={slide_right}
                    handleCurrentPage={handleCurrentPage}
                    storage={storage}
                    db={db}
                    getSingleImage={getSingleImage}
                  />
                </Suspense>
              }
            />
            <Route
              path="/management"
              element={
                <Suspense fallback={<>...</>}>
                  <Management
                    slide_left={slide_left}
                    slide_right={slide_right}
                    handleCurrentPage={handleCurrentPage}
                    storage={storage}
                    db={db}
                  />
                </Suspense>
              }
            />
            <Route
              path="/development"
              element={
                <Suspense fallback={<>...</>}>
                  <Development
                    slide_left={slide_left}
                    slide_right={slide_right}
                    handleCurrentPage={handleCurrentPage}
                    storage={storage}
                    db={db}
                  />
                </Suspense>
              }
            />
            <Route
              path="/healthsustainability"
              element={
                <Suspense fallback={<>...</>}>
                  <HealthSustainability
                    slide_left={slide_left}
                    slide_right={slide_right}
                    handleCurrentPage={handleCurrentPage}
                    storage={storage}
                  />
                </Suspense>
              }
            />
            <Route
              path="*"
              element={
                <Suspense fallback={<>...</>}>
                  <NotFound />
                </Suspense>
              }
            />
          </Routes>

          <Footer
            backgroundColor={navBackground}
            textColor={navText}
          />
          <div className={`overlay ${navHoverDim ? "visible" : ""}`}></div>
        </div>
      </BrowserRouter>
    </ParallaxProvider>
  );
}

export default App;
