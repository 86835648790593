import React from "react";

export default function CaretDown({textColor}) {
  return (
    <div className="caret-down-icon">
      <svg
        width="14"
        height="9"
        viewBox="0 0 13 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5 1.22223L6.5 6.77778L1.5 1.22223"
          stroke={textColor}
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
}
