import ListIcon from "../icons/ListIcon";
import React, { useEffect, useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { AnimatePresence, motion } from "framer-motion";
import HamburgerMenuExpanded from "./HamburgerMenuExpanded";
import { Link } from "react-router-dom";
import CustomDropdown from "./CustomDropdown";
import black_logo from "../Images/GalerieLiving_Logotype_W.webp";
import white_logo from "../Images/GalerieLiving_Logotype_K.jpg";
import XIcon from "../icons/XIcon";
export default function Navbar({
  backgroundColor,
  textColor,
  setNavHoverDim,
  navHoverDim,
}) {
  const [borderColor, setBorderColor] = useState("#D9D9D6");
  const max_width = useMediaPredicate("(min-width: 1000px)");
  const [expandMenu, setExpandMenu] = useState(false);

  const handleExpand = () => {
    setExpandMenu(!expandMenu);
  };

  useEffect(() => {
    if (textColor === "black") {
      setBorderColor("#D9D9D6");
    } else {
      setBorderColor("rgba(217, 217, 214, 0.3)");
    }
  }, []);

  return (
    <>
      {max_width ? (
        <>
          <div
            className="nav-bar"
            style={
              navHoverDim
                ? {
                    background: backgroundColor,
                    borderBottom: `1px solid ${borderColor}`,
                  }
                : { background: backgroundColor }
            }
          >
            <Link
              to="/"
              aria-label="galerie-logo"
              className="galerie-logo-link"
              data-testid="nav-galerie-logo"
            >
              {textColor === "white" ? (
                <>
                  <img
                    alt=""
                    className="galerie-logo"
                    src={black_logo}
                    width="16"
                    height="9"
                  />
                </>
              ) : (
                <>
                  <img
                    alt=""
                    className="galerie-logo"
                    src={white_logo}
                    width="16"
                    height="9"
                  />
                </>
              )}
            </Link>

            <div className="navbar-buttons">
              <CustomDropdown
                setNavHoverDim={setNavHoverDim}
                navHoverDim={navHoverDim}
                textColor={textColor}
                background_color={backgroundColor}
                dropdownTitle={"About Us"}
                dropdownLink={"about"}
                itemsDict={[
                  {
                    link: "healthsustainability",
                    title: "Health Sustainability",
                  },
                  { link: "team", title: "Team" },
                  { link: "careers", title: "Careers" },
                  { link: "press", title: "Press" },
                ]}
              />
              <CustomDropdown
                setNavHoverDim={setNavHoverDim}
                navHoverDim={navHoverDim}
                textColor={textColor}
                background_color={backgroundColor}
                dropdownTitle={"The Collection"}
                dropdownLink={"collections"}
                itemsDict={[
                  { link: "villagepark", title: "Village Park" },
                  { link: "corso", title: "Corso" },
                ]}
              />
              <CustomDropdown
                setNavHoverDim={setNavHoverDim}
                navHoverDim={navHoverDim}
                textColor={textColor}
                background_color={backgroundColor}
                dropdownTitle={"Our Companies"}
                dropdownLink={"companies"}
                itemsDict={[
                  { link: "capital", title: "Capital" },
                  { link: "development", title: "Development" },
                  { link: "management", title: "Management" },
                ]}
              />
              <Link
                to="/contact"
                className="navbar-btn"
                style={{ textDecoration: "none" }}
                data-testid="nav-contact-btn"
              >
                <p className="navbar-btn-text" data-testid="nav-contact-btn">
                  contact us
                </p>
              </Link>
            </div>
          </div>
        </>
      ) : (
        <div>
          {" "}
          <div
            className="frame7"
            style={
              textColor === "white"
                ? { background: backgroundColor, borderBottom: "none" }
                : {
                    background: backgroundColor,
                    borderBottom: "1px solid #D9D9D6",
                  }
            }
          >
            <Link
              to="/ "
              aria-label="galerie-logo"
              data-testid="nav-galerie-logo"
            >
              {textColor === "white" ? (
                <>
                  <img
                    alt=""
                    className="galerie-logo"
                    src={black_logo}
                    width="16"
                    height="9"
                  />
                </>
              ) : (
                <>
                  <img
                    alt=""
                    className="galerie-logo"
                    src={white_logo}
                    width="16"
                    height="9"
                  />
                </>
              )}
            </Link>

            {expandMenu ? (
              <>
                <div
                  className="menu-icon"
                  data-testid="open-hamburger-navbar-btn"
                  onClick={handleExpand}
                >
                  <XIcon size={36} color={textColor} weight="thin" />
                </div>
              </>
            ) : (
              <>
                <div
                  className="menu-icon"
                  data-testid="open-hamburger-navbar-btn"
                  onClick={handleExpand}
                >
                  <ListIcon size={36} color={textColor} weight="thin" />
                </div>
              </>
            )}
          </div>
          {expandMenu && (
            <>
              <AnimatePresence>
                <motion.div
                  className=""
                  id=""
                  key={expandMenu ? 1 : 2}
                  transition={{
                    y: { type: "spring", stiffness: 300, damping: 30 },
                    opacity: { duration: 0.2 },
                  }}
                  initial={{ y: -300, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 300 }}
                >
                  <HamburgerMenuExpanded
                    className="hamburger-menu-expanded"
                    handleExpand={handleExpand}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                  />
                </motion.div>
              </AnimatePresence>
            </>
          )}
        </div>
      )}
    </>
  );
}
