import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import { ref, getDownloadURL, listAll } from "firebase/storage";
import { AnimatePresence, motion } from "framer-motion";
import PlayIcon from "../corso_page/icons/PlayIcon";

export default function VideoTestimonial({
  storage,
  class_name = "",
  title_shown = true,
  background_color = "#F7F7F7",
  text_color = "black",
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [, setArrowButtons] = useState("");
  const [, setPrevIndex] = useState();
  const [animationDirection, setAnimationDirection] = useState(false);
  const [controls, setControls] = useState(false);
  const [testimonialVideo1, setTestimonialVideo1] = useState();
  const [testimonialVideo2, setTestimonialVideo2] = useState();
  const [testimonialVideo3, setTestimonialVideo3] = useState();

  const videoRef = useRef(null);
  let testimonials = [
    {
      video_url: testimonialVideo1,
      quote: "Coming to Corso was the best decision I have ever made.",
      testimonial_name: "Jane Kummel",
      video_file: "Jane Kummel.mov",
    },
    {
      video_url: testimonialVideo2,
      quote:
        "I’ve really seen a positive improvement in my parents quality of life.",
      testimonial_name: "Keith Bowden",
      video_file: "Keith and Joseph Bowden.mov",
    },
    {
      video_url: testimonialVideo3,
      quote:
        "The food is excellent and I’m enjoying myself. I’m glad I made the move.",
      testimonial_name: "Peggy Rogers",
      video_file: "Peggy Rogers.mov",
    },
  ];
  const handleControls = () => {
    if (controls === false) {
      setControls(true);
      videoRef.current.play();
    } else if (controls === true) {
      setControls(false);

      videoRef.current.pause();
    }
  };

  const goToPrevious = () => {
    setAnimationDirection(true);
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? testimonials.length - 1 : currentIndex - 1;
    setPrevIndex(currentIndex);
    setCurrentIndex(newIndex);
    setControls(false);
    setArrowButtons(false);
  };
  const goToNext = () => {
    setAnimationDirection(false);
    const isLastSlide = currentIndex === testimonials.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setPrevIndex(currentIndex);
    setCurrentIndex(newIndex);
    setControls(false);
    setArrowButtons(true);
  };

  const getAllVideos = () => {
    try {
      const listRef = ref(storage, `corso-landing-page`);
      listAll(listRef).then((res) => {
        res.items.forEach((itemRef) => {
          getDownloadURL(itemRef).then((url) => {
            const path = itemRef._location.path_.split("/")[1];
            switch (path) {
              case testimonials[0].video_file:
                setTestimonialVideo1(url);
                break;
              case testimonials[1].video_file:
                setTestimonialVideo2(url);
                break;
              case testimonials[2].video_file:
                setTestimonialVideo3(url);
                break;
            }
          });
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      getAllVideos();
    }, "2000");
  }, []);

  return testimonials.length < 2 ? (
    <></>
  ) : (
    <>
      <div className={`testimonial-container ${class_name}`}>
        {title_shown && (
          <>
            {" "}
            <p className="testimonials-section-title">Testimonials</p>
          </>
        )}

        <div
          className="next-arrow"
          onClick={goToNext}
          data-testid="testimonial-next-btn"
        >
          <ArrowRight
            className="arrow-right-icon"
            data-testid="testimonial-next-btn"
          />
        </div>
        {currentIndex === 0 ? (
          <>
            <div
              className="prev-arrow-disabled"
              style={{ cursor: "not-allowed" }}
            >
              <ArrowLeft className="arrow-left-icon-disabled" color="#D9D9D6" />
            </div>
          </>
        ) : (
          <>
            <div
              className="prev-arrow"
              onClick={goToPrevious}
              data-testid="testimonial-prev-btn"
            >
              <ArrowLeft
                className="arrow-left-icon"
                data-testid="testimonial-prev-btn"
              />
            </div>
          </>
        )}

        <AnimatePresence initial={false} mode="wait">
          <motion.div
            className="testimonial-card div-copy"
            style={{ backgroundColor: background_color, color: text_color }}
            transition={{
              x: { type: "spring", stiffness: 100, damping: 20, duration: 0.4 },
            }}
            key={currentIndex}
            initial={
              animationDirection
                ? { x: -100, opacity: 0 }
                : { x: 100, opacity: 0 }
            }
            animate={{ x: 0, opacity: 1 }}
            exit={
              animationDirection ? { x: 0, opacity: 0 } : { x: 0, opacity: 0 }
            }
          >
            <div className="video-container">
              <video
                alt=""
                id={testimonials[currentIndex].video_file}
                controls={controls}
                ref={videoRef}
                onClick={handleControls}
                name="testimonial_play"
                className="testimonial-video"
                src={`${testimonials[currentIndex].video_url}#t=0.001`}
              />
              <div className="icon-container">
                <PlayIcon
                  className="play-icon"
                  onClick={handleControls}
                  style={
                    controls
                      ? { display: "none", fill: "white" }
                      : { fill: "white", display: "block" }
                  }
                  data-testid={`play-${testimonials[currentIndex].video_file}#t=0.001`}
                />
              </div>
            </div>
            <div className="testimonial-body">
              <h3 className="testimonial-quote">
                “{testimonials[currentIndex].quote}”
              </h3>
              <p className="testimonial-name">
                {" "}
                {testimonials[currentIndex].testimonial_name}
              </p>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </>
  );
}
