import React from "react";

export default function ListIcon({ color, size }) {
  return (
    <div className="list-icon">
      <svg
        width={size}
        height={size}
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="menu-icon" clipPath="url(#clip0_263_1370)">
          <path
            id="Vector"
            d="M5.46875 17.5H29.5312"
            stroke={color}
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M5.46875 8.75H29.5312"
            stroke={color}
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M5.46875 26.25H29.5312"
            stroke={color}
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
}
