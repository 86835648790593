import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import CardGrey from "../components/CardGrey";
import CardWhite from "../components/CardWhite";
import { ArrowUpRight } from "phosphor-react";
import VideoTestimonial from "../components/VideoTestimonial";
import DarkRedButton from "../components/DarkRedButton";
import { Link } from "react-router-dom";
import { useMediaPredicate } from "react-media-hook";
import home_img from "../Images/home-hero-1x.webp";
import { getAllPhotos } from "../utils/Helpers";
import { Parallax } from "react-scroll-parallax";
import { useScrollDirection } from "react-use-scroll-direction";
export default function Home({ storage, db, handleCurrentPage, slide_right }) {
  const [hoverColor, setHoverColor] = useState(false);
  const max_width = useMediaPredicate("(max-width: 760px)");
  const max_width2 = useMediaPredicate("(max-width: 1230px)");
  const { isScrollingUp, isScrollingDown, isScrolling } = useScrollDirection();
  const [scroll, setScroll] = useState(false);
  const hoverCard = (div) => {
    setHoverColor(div);
  };

  const handleScroll = () => {
    const x = scroll;
    if (isScrollingDown === true) {
      setScroll(true);
    }
    if (isScrollingUp === true) {
      setScroll(false);
    }
    if (isScrolling === false) {
      setScroll(x);
    }
  };
  useEffect(() => {
    handleCurrentPage();
    window.scroll(0, 0);
    getAllPhotos("home");
    getAllPhotos("contact");
  }, []);

  useEffect(() => {
    handleScroll();
  }, [isScrollingUp, isScrollingDown, isScrolling]);
  useEffect(() => {
    window.dataLayer.push({
      event_name: "pageview",
      page: {
        title: "Home Page",
      },
    });
  }, []);
  return (
    <div className="home-container">
      <motion.div {...slide_right} className="home-hero">
        <Parallax speed={max_width2 ? 0 : -3}>
          <h1 className="home-hero-title ">
            Creating <span style={{ color: "#aa8c2c" }}>more good days</span>{" "}
            for seniors, families, and communities
          </h1>
        </Parallax>
      </motion.div>

      <img
        width={max_width ? "390px" : "1340px"}
        height={max_width ? "230px" : "800px"}
        className="home-block-1-img"
        src={home_img}
        id={max_width ? "home-block-1-img-mobile" : "home-block-1-img"}
        alt="A woman with grandson potting flowers together."
      />

      <Parallax
        translateY={max_width2 ? [0, 0] : ["150px", "0px"]}
        easing={[0.25, 1, 0.25, 1]}
      >
        <div className="home-offset-text-block">
          <div className="home-blurb-1">What's your signature?</div>
          <p className="home-offset-txt">
            Through every aspect of our approach, we remain committed to
            providing dignity, excellence, innovation, and happiness to all who
            enter our communities.
          </p>
          <div className="home-about-btn-div">
            <DarkRedButton
              class_name="home-about-btn"
              btnText={"About Us"}
              btnLink={"/about"}
              btnWidth={"760px"}
              data_test_id="home-about-btn"
            />
          </div>
        </div>
      </Parallax>
      <Parallax
        translateY={max_width2 ? [0, 0] : ["150px", "0px"]}
        easing={[0.25, 1, 0.25, 1]}
      >
        <VideoTestimonial
          storage={storage}
          background_color="#041C2C"
          text_color="white"
          db={db}
          class_name="home-page-testimonials"
        />
      </Parallax>
      <Parallax
        translateY={max_width2 ? [0, 0] : ["150px", "0px"]}
        easing={[0.25, 1, 0.25, 1]}
      >
        <div className="home-companies">
          <p className="home-companies-subhead">Companies</p>
          <h3 className="home-companies-text">
            Our diverse family of companies establish a collaborative union that
            embodies bold strategy, visionary foresight, and class-redefining
            leadership.
          </h3>
          <div className="home-companies-cards-div">
            <motion.div
              className="home-companies-card"
              onMouseOver={() => hoverCard("capital")}
              onMouseOut={() => hoverCard(false)}
              initial={
                max_width2
                  ? { opacity: 1, y: 0 }
                  : scroll
                  ? { opacity: 0, y: -75 }
                  : { opacity: 0, y: 75 }
              }
              whileInView={{ opacity: 1, y: 0 }}
              transition={
                max_width2 ? { duration: 0 } : { type: "tween", duration: 0.5 }
              }
            >
              <Link to="/capital" data-testid="home-capital-card">
                <img
                  id={
                    max_width
                      ? "galerieCapitalHomeCard-mobile"
                      : "galerieCapitalHomeCard"
                  }
                  alt="Galerie Living team conversing with Galerie Capital Logo overlay."
                  data-testid="home-capital-card"
                  width="360"
                  height="500"
                />
              </Link>
              <Link
                to="/capital"
                className="home-companies-card-body"
                style={
                  hoverColor === "capital"
                    ? { color: "#A12A2B", textDecoration: "none" }
                    : { color: "black", textDecoration: "none" }
                }
                data-testid="home-capital-card"
              >
                <p>galerie capital</p>
                <ArrowUpRight size={20} />
              </Link>
            </motion.div>
            <motion.div
              className="home-companies-card"
              onMouseOver={() => hoverCard("dev")}
              onMouseOut={() => hoverCard(false)}
              initial={
                max_width2
                  ? { opacity: 1, y: 0 }
                  : scroll
                  ? { opacity: 0, y: -75 }
                  : { opacity: 0, y: 75 }
              }
              whileInView={{ opacity: 1, y: 0 }}
              transition={
                max_width2
                  ? { duration: 0 }
                  : { type: "tween", duration: 0.5, delay: 0.2 }
              }
            >
              <Link to="/development" data-testid="home-development-card">
                <img
                  id={
                    max_width
                      ? "galerieDevelopmentHomeCard-mobile"
                      : "galerieDevelopmentHomeCard"
                  }
                  alt="Corso Atlanta sketch in front of Corso Atlanta Building with Galerie Development Logo overlay"
                  data-testid="home-development-card"
                  width="360"
                  height="500"
                />
              </Link>
              <Link
                to="/development"
                className="home-companies-card-body"
                style={
                  hoverColor === "dev"
                    ? { color: "#A12A2B", textDecoration: "none" }
                    : { color: "black", textDecoration: "none" }
                }
                data-testid="home-development-card"
              >
                <p>galerie Development</p>
                <ArrowUpRight size={20} />
              </Link>
            </motion.div>
            <motion.div
              className="home-companies-card"
              onMouseOver={() => hoverCard("management")}
              onMouseOut={() => hoverCard(false)}
              initial={
                max_width2
                  ? { opacity: 1, y: 0 }
                  : scroll
                  ? { opacity: 0, y: -75 }
                  : { opacity: 0, y: 75 }
              }
              whileInView={{ opacity: 1, y: 0 }}
              transition={
                max_width2
                  ? { duration: 0 }
                  : { type: "tween", duration: 0.5, delay: 0.3 }
              }
            >
              <Link to="/management" data-testid="home-management-card">
                <img
                  id={
                    max_width
                      ? "galerieManagementHomeCard-mobile"
                      : "galerieManagementHomeCard"
                  }
                  alt="Nurse and patient smiling and looking at a tablet with Galerie Management Logo overlay"
                  data-testid="home-management-card"
                  width="360"
                  height="500"
                />
              </Link>
              <Link
                to="/management"
                className="home-companies-card-body"
                style={
                  hoverColor === "management"
                    ? { color: "#A12A2B", textDecoration: "none" }
                    : { color: "black", textDecoration: "none" }
                }
                data-testid="home-management-card"
              >
                <p>galerie Management</p>
                <ArrowUpRight size={20} />
              </Link>
            </motion.div>
          </div>
        </div>
      </Parallax>
      <Parallax
        translateY={max_width2 ? [0, 0] : ["150px", "0px"]}
        easing={[0.25, 1, 0.25, 1]}
      >
        <CardGrey
          imgId={max_width ? "home-card-1-img-mobile" : "home-card-1-img"}
          cardTitle={"The Faces of Galerie"}
          cardText={
            "Our team of established visionaries and industry trailblazers bring a united vision of better senior living to fruition through a commitment to excellent intent, approach, and execution."
          }
          buttonLink={"/team"}
          buttonText={"The Team"}
          flexStyle={"row-reverse"}
          class_name={"home-card-2"}
          data_test_id="home-faces-of-galerie-btn"
          alt_tag="Galerie Living team smiling outside of Corso Atlanta Senior Living Building."
        />
      </Parallax>
      <Parallax
        translateY={max_width2 ? [0, 0] : ["150px", "0px"]}
        easing={[0.25, 1, 0.25, 1]}
      >
        <CardWhite
          imgId={max_width ? "home-card-2-img-mobile" : "home-card-2-img"}
          cardTitle={"A History of Happiness"}
          cardText={
            "Our expanding track record has propelled our brand to the forefront of numerous esteemed publications. For insights into our vision, values, and upcoming developments, see Galerie in the Press."
          }
          buttonLink={"/press"}
          buttonText={"Galerie in the press"}
          flexStyle={"row"}
          class_name={"home-card-1"}
          data_test_id="home-galerie-in-press-btn"
          alt_tag="Exterior of Corso Atlanta Senior Living Building."
        />
      </Parallax>
      <div className="home-divider"></div>

      <div className="contact-us-collection-container">
        <h1 className="home-collection-title">The Collection</h1>
        <div className="contact-collection-card-container">
          <motion.div
            className="contact-collection-card"
            onMouseOver={() => hoverCard("corso")}
            onMouseOut={() => hoverCard(false)}
            initial={
              max_width2
                ? { opacity: 1, y: 0 }
                : scroll
                ? { opacity: 0, y: -75 }
                : { opacity: 0, y: 75 }
            }
            whileInView={{ opacity: 1, y: 0 }}
            transition={
              max_width2 ? { duration: 0 } : { type: "tween", duration: 0.5 }
            }
          >
            <Link to="/corso" data-testid="home-corso-card">
              <img
                loading="lazy"
                id={
                  max_width
                    ? "collection-mobile-corso"
                    : "collection-desktop-corso"
                }
                className="contact-img"
                alt="Exterior of Corso Atlanta Senior Living Building with the Corso Logo overlaid."
                data-testid="home-corso-card"
                width="640"
                height="360"
              />
            </Link>
            <Link
              to="/corso"
              className="contact-collection-card-text"
              style={
                hoverColor === "corso"
                  ? { color: "#A12A2B", textDecoration: "none" }
                  : { color: "black", textDecoration: "none" }
              }
              data-testid="home-corso-card"
            >
              <p>Corso</p>
              <ArrowUpRight className="contact-arrow-icon" size={20} />
            </Link>
          </motion.div>
          <motion.div
            className="contact-collection-card"
            onMouseOver={() => hoverCard("vp")}
            onMouseOut={() => hoverCard(false)}
            initial={
              max_width2
                ? { opacity: 1, y: 0 }
                : scroll
                ? { opacity: 0, y: -75 }
                : { opacity: 0, y: 75 }
            }
            whileInView={{ opacity: 1, y: 0 }}
            transition={
              max_width2
                ? { duration: 0 }
                : { type: "tween", duration: 0.5, delay: 0.2 }
            }
          >
            <Link to="/villagepark" data-testid="home-vp-card">
              <img
                loading="lazy"
                id={
                  max_width ? "collection-mobile-vp" : "collection-desktop-vp"
                }
                className="contact-img"
                alt="Exterior of Village Park Senior Living Building with the Village Park Logo overlaid."
                data-testid="home-vp-card"
                width="640"
                height="360"
              />
            </Link>
            <Link
              className="contact-collection-card-text"
              to="/villagepark"
              style={
                hoverColor === "vp"
                  ? { color: "#A12A2B", textDecoration: "none" }
                  : { color: "black", textDecoration: "none" }
              }
              data-testid="home-vp-card"
            >
              <p>Village Park</p>
              <ArrowUpRight className="contact-arrow-icon" size={20} />
            </Link>
          </motion.div>
        </div>
      </div>

      <div className="home-divider"></div>
      <Parallax
        translateY={max_width2 ? [0, 0] : ["150px", "0px"]}
        easing={[0.25, 1, 0.25, 1]}
      >
        <div className="home-more-info">
          <h1 className="home-more-info-title">Need More Information?</h1>
          <p className="home-more-info-text">
            We're happy to help. Whether you have questions, feedback, or just
            want to say hello, please don't hesitate to reach out. We'd love to
            hear from you and assist in any way we can.
          </p>
          <div>
            <DarkRedButton
              class_name="home-more-info-btn"
              btnLink={"/contact"}
              btnText={"Contact Us"}
              data_test_id="home-contact-btn"
            />
          </div>
        </div>
      </Parallax>
    </div>
  );
}
