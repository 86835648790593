import React, { useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";
import PrivacyPolicy from "../privacy_policy/PrivacyPolicy";
import { X } from "@phosphor-icons/react";
import black_logo from "../Images/GalerieLiving_Logotype_W.webp";
import white_logo from "../Images/GalerieLiving_Logotype_K.jpg";
export default function Footer({ backgroundColor, textColor }) {
  const max_width = useMediaPredicate("(max-width: 1188px)");
  const [showModal, setShowModal] = useState(false);

  const privacyModal = () => {
    setShowModal(!showModal);
  };
  return (
    <div className="footer-container" style={{ background: backgroundColor }}>
      <div className="footer-div">
        <div className="footer-header-div">
          <Link to="/">
            {textColor === "white" ? (
              <>
                <img
                  alt=""
                  className="galerie-logo"
                  src={black_logo}
                  width="16"
                  height="9"
                />
              </>
            ) : (
              <>
                <img
                  alt=""
                  className="galerie-logo"
                  src={white_logo}
                  width="16"
                  height="9"
                />
              </>
            )}
          </Link>
        </div>

        <div className="footer-columns-div">
          <div className="footer-line" />
          <div className="footer-column">
            <div className="footer-description">
              <Link
                to="/about"
                className="footer-header"
                data-testid="footer-about-link"
                style={{ color: textColor }}
              >
                About Us
              </Link>
              {!max_width && <div className="footer-red-line"></div>}
              <Link
                to="/healthsustainability"
                className="footer-link"
                data-testid="footer-team-link"
                style={{ color: textColor }}
              >
                Health Sustainability
              </Link>
              <Link
                to="/team"
                className="footer-link"
                data-testid="footer-team-link"
                style={{ color: textColor }}
              >
                Team
              </Link>
              <Link
                to="/careers"
                className="footer-link"
                data-testid="footer-careers-link"
                style={{ color: textColor }}
              >
                Careers
              </Link>
              <Link
                to="/press"
                className="footer-link"
                data-testid="footer-press-link"
                style={{ color: textColor }}
              >
                Press
              </Link>
            </div>
          </div>

          <div className="footer-line" />

          <div className="footer-column">
            <div className="footer-description">
              <Link
                to="/collections"
                className="footer-header"
                data-testid="footer-collections-link"
                style={{ color: textColor }}
              >
                The Collection
              </Link>
              {!max_width && <div className="footer-red-line"></div>}
              <Link
                to="/villagepark"
                className="footer-link"
                data-testid="footer-vp-link"
                style={{ color: textColor }}
              >
                Village Park
              </Link>
              <Link
                to="/corso"
                className="footer-link"
                data-testid="footer-corso-link"
                style={{ color: textColor }}
              >
                Corso
              </Link>
            </div>
          </div>

          <div className="footer-line" />
          <div className="footer-column">
            <div className="footer-description">
              <Link
                to="/companies"
                className="footer-header"
                data-testid="footer-companies-link"
                style={{ color: textColor }}
              >
                Our Companies
              </Link>
              {!max_width && <div className="footer-red-line"></div>}
              <Link
                to="/capital"
                className="footer-link"
                data-testid="footer-capital-link"
                style={{ color: textColor }}
              >
                Galerie Capital
              </Link>
              <Link
                to="/development"
                className="footer-link"
                data-testid="footer-development-link"
                style={{ color: textColor }}
              >
                Galerie Development
              </Link>
              <Link
                to="/management"
                className="footer-link"
                data-testid="footer-management-link"
                style={{ color: textColor }}
              >
                Galerie Management
              </Link>
            </div>
          </div>

          <div className="footer-line" />

          <div className="footer-column">
            <div className="footer-description">
              <Link
                to="/contact"
                className="footer-header"
                data-testid="footer-contact-link"
                style={{ color: textColor }}
              >
                Contact Us
              </Link>
              {!max_width && <div className="footer-red-line"></div>}
              <a
                href="http://maps.google.com/?q=3200 3424 Peachtree Rd NE Atlanta, GA 30326"
                rel="noreferrer"
                target="_blank"
                className="footer-address"
                data-testid="footer-address-link"
                style={{ color: textColor }}
              >
                3424 Peachtree Rd NE <br />
                Atlanta, GA 30326
              </a>
              {max_width ? (
                <>
                  <a
                    href="tel:+6786778900"
                    className="footer-phone-number"
                    data-testid="footer-phone-link"
                    style={{ color: textColor }}
                  >
                    (678) 677-8900
                  </a>
                </>
              ) : (
                <>
                  <p
                    className="footer-phone-number"
                    data-testid="footer-phone-link"
                    style={{ color: textColor }}
                  >
                    (678) 677-8900
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="copyright-fha-disclaimer">
          <p>
            Galerie Living is an equal housing opportunity. In support of and in
            compliance with the Fair Housing Act. All Galerie Living communities
            do not discriminate against any person because of race, color,
            religion, national origin, sex, familial status, disability or any
            other specific classes protected by applicable laws.
          </p>
          <p style={{ marginTop: "5px" }}>
            Vendors may contact the Corporate Office to report system failures,
            incidents, concerns, or complaints at{" "}
            <a href="mailto:vendorrequests@galerieliving.com">
              vendorrequests@galerieliving.com
            </a>
            .
          </p>
        </div>

        <div className="copyright-description">
          <p>Copyright © 2023 Galerie Living |</p>
          <p
            style={{ marginLeft: "2px" }}
            className="privacy-policy-link"
            data-testid="footer-privacy-policy-link"
            onClick={privacyModal}
          >
            Privacy Policy
          </p>
        </div>
      </div>

      {showModal ? (
        <>
          <div className="privacy-policy-container">
            <div
              style={{
                position: "sticky",
                top: "0px",
                right: "0px",
                marginBottom: "50px",
              }}
            >
              <X
                size={25}
                color="grey"
                onClick={privacyModal}
                style={{ float: "right" }}
              />
            </div>

            <PrivacyPolicy />
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}
