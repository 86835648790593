import React from "react";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";

export default function CardWhite({
  imgId = null,
  cardText = "",
  cardTitle = "",
  width = "992px",
  buttonLink = "",
  buttonText = "read more",
  flexStyle = "row",
  number = null,
  class_name = "",
  buttonShown = true,
  btnLinkExternal = false,
  data_test_id = "",
  alt_tag = "",
  cardText2,
}) {
  const max_width = useMediaPredicate(`(max-width: ${width})`);
  return (
    <div
      className={`card-white ${class_name}`}
      style={
        max_width ? { flexDirection: "column" } : { flexDirection: flexStyle }
      }
    >
      {imgId ? (
        <>
          <img
            loading="lazy"
            alt={alt_tag}
            id={imgId}
            className="card-white-img"
          />
        </>
      ) : (
        <></>
      )}
      <div className="card-white-body">
        {number ? (
          <>
            <div className="about-values-card-number">
              <p className="about-values-card-num1">{number}</p>
              <p className="about-values-card-num2">/04</p>
            </div>
          </>
        ) : (
          <></>
        )}

        <h1 className="card-white-title">{cardTitle}</h1>
        <p className="card-white-text">{cardText}</p>
        {cardText2 ? (
          <>
            <p className="card-white-text">{cardText2}</p>
          </>
        ) : (
          <></>
        )}

        {buttonShown ? (
          <>
            {btnLinkExternal ? (
              <>
                <a
                  href={btnLinkExternal}
                  target="_blank"
                  rel="noreferrer"
                  className="card-white-btn"
                  style={{ textDecoration: "none" }}
                  data-testid={data_test_id}
                >
                  {buttonText}
                </a>
              </>
            ) : (
              <>
                <Link
                  to={buttonLink}
                  className="card-white-btn"
                  style={{ textDecoration: "none" }}
                  data-testid={data_test_id}
                >
                  {buttonText}
                </Link>
              </>
            )}

            {/* <DarkRedButton class_name={'card-white-btn'} btnText={buttonText} btnLink={buttonLink} btnWidth={width}/> */}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
