import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useMediaPredicate } from "react-media-hook";
import health_hero from "../Images/health-hero-1x.webp";
import { getAllPhotos } from "../utils/Helpers";
import { Parallax } from "react-scroll-parallax";

export default function HealthSustainability({
  handleCurrentPage,
  slide_right,
  slide_left,
}) {
  const max_width = useMediaPredicate("(max-width: 760px)");
  const max_width2 = useMediaPredicate("(max-width: 1230px)");

  useEffect(() => {
    getAllPhotos("healthsustainability");
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    handleCurrentPage();
    window.dataLayer.push({
      event_name: "pageview",
      page: {
        title: "Health Sustainability Page",
      },
    });
  }, []);

  return (
    <div className="health-container">
      <Parallax speed={max_width2 ? 0 : -3}>
        <motion.div className="health-hero" {...slide_right}>
          <p className="health-header-title">Health Sustainability</p>
          <p className="health-header-text">
            Live life to the fullest: Health Sustainability opens doors to{" "}
            <span style={{ color: "#aa8c2c" }}>more good days</span>
          </p>
        </motion.div>
      </Parallax>

      <img
        className="health-hero-img"
        id={max_width ? "health-img-1-mobile" : "health-img-1"}
        src={health_hero}
        alt="Two seniors and their grandchildren holding paintings they made in an art class."
      />

      <div className="health-overview-container">
        <div className="health-overview-body">
          <Parallax
            translateY={max_width2 ? [0, 0] : [40, 0]}
            easing={[0.25, 1, 0.25, 1]}
          >
            <div className="health-overview-card">
              <img
                className="health-overview-card-img"
                id={max_width ? "health-img-2-mobile" : "health-img-2"}
                alt="New residents sitting at a table and smiling."
              />
              <div className="health-overview-card-body">
                <div className="health-overview-card-title">
                  <p className="">
                    <span className="health-overview-span">No. 1</span>
                    <span className="health-overview-span2">Anticipate</span>
                  </p>
                </div>
                <p className="health-overview-card-text">
                  By opening inclusive dialogues with all community stakeholders
                  and applying the broad and informative data found in our
                  digital systems, we're able to provide proactive solutions to
                  physical, cognitive, or emotional changes.
                </p>
              </div>
            </div>
          </Parallax>
          <Parallax
            translateY={max_width2 ? [0, 0] : [40, 0]}
            easing={[0.25, 1, 0.25, 1]}
          >
            <div className="health-overview-card">
              <img
                className="health-overview-card-img"
                id={max_width ? "health-img-3-mobile" : "health-img-3"}
                alt="Two Galerie Living employees looking at an ipad and conversing."
              />
              <div className="health-overview-card-body">
                <div className="health-overview-card-title">
                  <p className="">
                    <span className="health-overview-span">No. 2</span>
                    <span className="health-overview-span2">Empower</span>
                  </p>
                </div>
                <p className="health-overview-card-text">
                  This information empowers proactive decision making and allows
                  everyone in our health sustainability ecosystem to be a
                  supportive actor in providing great care, great service, and
                  great experiences.
                </p>
              </div>
            </div>
          </Parallax>
          <Parallax
            translateY={max_width2 ? [0, 0] : [40, 0]}
            easing={[0.25, 1, 0.25, 1]}
          >
            <div className="health-overview-card">
              <img
                className="health-overview-card-img"
                id={max_width ? "health-img-4-mobile" : "health-img-4"}
                alt="A florist and resident looking at flowers in the florist shop at Corso Atlanta."
              />
              <div className="health-overview-card-body">
                <div className="health-overview-card-title">
                  <p className="">
                    <span className="health-overview-span">No. 3</span>
                    <span className="health-overview-span2">Celebrate</span>
                  </p>
                </div>
                <p className="health-overview-card-text">
                  Through welcoming home environments, engaged members, and
                  supportive systems, our communities become spaces to celebrate
                  the wellness victories, day-to-day moments, and lasting
                  relationships that inspire more good days.
                </p>
              </div>
            </div>
          </Parallax>
        </div>
      </div>
      <div className="health-divider-line"></div>

      <div className="health-model-container">
        <Parallax
          translateY={max_width2 ? ["0", "0"] : ["150px", "0px"]}
          easing={[0.25, 1, 0.25, 1]}
        >
          <p className="health-model-subtitle">Imagine a community where...</p>
        </Parallax>
        <Parallax
          translateY={max_width2 ? ["0", "0"] : ["150px", "0px"]}
          easing={[0.25, 1, 0.25, 1]}
        >
          <div className="health-model-body">
            <ul className="health-model-list">
              <li>
                <p className="health-model-num">01 - </p>
                <p>Care workers are recognized as care heroes</p>
              </li>
              <li>
                <p className="health-model-num">02 - </p>
                <p>Individuals can live healthier and happier for longer</p>
              </li>
              <li>
                <p className="health-model-num">03 - </p>
                <p>We can anticipate and address needs before they occur</p>
              </li>
              <li>
                <p className="health-model-num">04 - </p>
                <p>Everyone is empowered to reach their wellness goals</p>
              </li>
              <li>
                <p className="health-model-num">05 - </p>
                <p>Grandkids have more memories with their grandparents</p>
              </li>
              <li>
                <p className="health-model-num">06 - </p>
                <p>Turning 81 is as celebrated as turning 21</p>
              </li>
            </ul>
            <img
              className="health-model-img"
              id={max_width ? "health-img-5-mobile" : "health-img-5"}
              alt="A resident and her granddaughter buying a lollipop at a candy store at Corso Atlanta."
            />
          </div>
        </Parallax>
      </div>
      <Parallax
        translateY={max_width2 ? ["0", "0"] : ["150px", "0px"]}
        easing={[0.25, 1, 0.25, 1]}
      >
        <div className="health-block-1">
          <img
            className="health-block-1-img"
            id={max_width ? "health-img-6-mobile" : "health-img-6"}
            alt="A woman at a library at Corso senior living speaking to children."
          />
          <p>Imagine a community with Health Sustainability.</p>
        </div>
      </Parallax>
      <div className="health-divider-line"></div>

      <Parallax
        translateY={max_width2 ? ["0", "0"] : ["150px", "0px"]}
        easing={[0.25, 1, 0.25, 1]}
      >
        <div className="health-card-1">
          <div>
            <p className="health-card-1-text">
              We make investments in the people, the spaces, the technologies,
              and the services that fulfill our health sustainability
              environments. Everybody is a part of it and everybody is supported
              through it.
            </p>
          </div>
          <img
            className="health-card-1-img"
            id={max_width ? "health-img-7-mobile" : "health-img-7"}
            alt="A Corso Atlanta chef smiling at the camera behind the counter in the cafe."
          />
        </div>
      </Parallax>

      <div className="health-split-block-1">
        <motion.p {...slide_right} className="health-split-1a">
          Anticipating physical, cognitive, and emotional changes so care and
          support is proactively adjusted to maximize seniors’ health-span
          (years spent in quality health.)
        </motion.p>
        <motion.p {...slide_left} className="health-split-1b">
          Empowering each contributor to the senior living ecosystem to be an
          active and engaged partner in fostering the complete well-being of
          seniors.
        </motion.p>
      </div>
      <Parallax
        translateY={max_width2 ? ["0", "0"] : ["150px", "0px"]}
        easing={[0.25, 1, 0.25, 1]}
      >
        <img
          className="health-block-2-img"
          id={max_width ? "health-img-8-mobile" : "health-img-8"}
          alt="Residents doing yoga together outside."
        />
      </Parallax>
      <Parallax speed={-3}>
        <div className="health-split-block-2">
          <motion.p {...slide_right} className="health-split-2a">
            Celebrating the good days and shared efforts to positively improve
            the lives of seniors and those who care for them.
          </motion.p>
          <motion.p {...slide_left} className="health-split-2b">
            Health Sustainability’s holistic approach ensures that everyone who
            walks through Galerie’s doors is met with dignity, excellence,
            innovation, and more good days.
          </motion.p>
        </div>
      </Parallax>
    </div>
  );
}
