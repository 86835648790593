import React from "react";
import { useMediaPredicate } from "react-media-hook";
import { Link } from "react-router-dom";

export default function DarkRedButton({
  btnWidth = "760px",
  btnText = "",
  btnLink = "",
  class_name = "",
  btnLinkExternal = false,
  data_test_id = "",
}) {
  const max_width = useMediaPredicate(`(max-width: ${btnWidth})`);
  return (
    <>
      {max_width ? (
        <>
          {btnLinkExternal ? (
            <>
              <a
                href={btnLinkExternal}
                target="_blank"
                rel="noreferrer"
                className={`dark-red-btn ${class_name}`}
                style={{
                  textDecoration: "none",
                  width: "100%",
                  maxWidth: "400px",
                  minWidth: "180px",
                  color: "white",
                }}
                data-testid={data_test_id}
              >
                {btnText}
              </a>
            </>
          ) : (
            <>
              <Link
                className={`dark-red-btn ${class_name}`}
                to={`${btnLink}`}
                style={{
                  textDecoration: "none",
                  width: "100%",
                  maxWidth: "400px",
                  minWidth: "180px",
                }}
                data-testid={data_test_id}
              >
                <p
                  style={{ margin: "auto", marginBottom: "0px" }}
                  data-testid={data_test_id}
                >
                  {btnText}
                </p>
              </Link>
            </>
          )}
        </>
      ) : (
        <>
          {btnLinkExternal ? (
            <>
              <a
                href={btnLinkExternal}
                target="_blank"
                rel="noreferrer"
                className={`dark-red-btn ${class_name}`}
                style={{ textDecoration: "none" }}
                data-testid={data_test_id}
              >
                {btnText}
              </a>
            </>
          ) : (
            <>
              <Link
                className={`dark-red-btn ${class_name}`}
                to={`${btnLink}`}
                style={{ textDecoration: "none" }}
                data-testid={data_test_id}
              >
                <p
                  style={{ margin: "auto", marginBottom: "0px" }}
                  data-testid={data_test_id}
                >
                  {btnText}
                </p>
              </Link>
            </>
          )}
        </>
      )}
    </>
  );
}
